export const useModalBackdrop = () => {
  const modals = document.querySelector('#modals')

  const activate = () => {
    modals?.classList.add('active-backdrop')
    modals?.classList.remove('pointer-events-none')
  }

  const deactivate = () => {
    modals?.classList.remove('active-backdrop')
    modals?.classList.add('pointer-events-none')
  }

  return {
    activate,
    deactivate,
  }
}
